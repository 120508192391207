import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import emailjs from 'emailjs-com';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './HomePage.css';
import Image1 from './assets/home-page-carousal-img1.png';
import CompanyProfile from './assets/company-profile-img.png';
import moneysvg from './assets/money.svg';
import scalablility from './assets/scalability.svg';
import goodrating from './assets/good-rating.svg';
import certificate1 from './assets/certificate-1.png';
import certificate2 from './assets/certificate-2.png';
import certificate3 from './assets/certificate-3.png';
import certificate4 from './assets/certificate-4.png';
import certificate5 from './assets/certificate-5.png';
import truck from './assets/truck.svg';
import gardener from './assets/gardener.svg';
import humanAssets from './assets/human-assets.svg';
import saving from './assets/saving.svg';
import tracking from './assets/tracking.svg';
import wheel from './assets/wheel.svg';
import client1 from '../../assets/client-118.png';
import client2 from '../../assets/client-119.png';
import client3 from '../../assets/client-121.png';
import client4 from '../../assets/client-123.png';
import client5 from '../../assets/client-124.png';
import client6 from '../../assets/client-125.png';
import client7 from '../../assets/client-126.png';
import client8 from '../../assets/client-127.png';
import client9 from '../../assets/client-128.png';
import client10 from '../../assets/client-129.png';
import client11 from '../../assets/client-130.png';
import client12 from '../../assets/client-131.png';
import client13 from '../../assets/client-132.png';
import client14 from '../../assets/client-133.png';
import client15 from '../../assets/client-3.png';
import client16 from '../../assets/client-5.png';
import client17 from '../../assets/client-6.png';
import client18 from '../../assets/client-7.png';
import client19 from '../../assets/client-8.png';

import { ReactComponent as SendIcon } from './assets/send-icon.svg';
import TransportTruck from './assets/transport-truck-1.png';
import ImageSlider from '../../Components/carousel/carousel';
import { useNavigate } from 'react-router-dom';

const defaultHomePageJSON = {
  header: {
    title: 'Your trusted transport partner',
    image: Image1,
    subtitle: 'Aapki Jeet, Hamari Jeet',
    buttons: ['Contact Us', 'Our Services']
  },
  companyProfile: {
    title: 'COMPANY PROFILE',
    image: CompanyProfile,
    description:
      'Jeet Roadways, based in the West Midlands, specializes in transporting goods that are uniquely shaped or challenging to handle, along with standard palletized items.',
    descriptionFooter:
      'Choose Jeet Roadways for reliable, efficient, and cost-effective transportation solutions tailored to your specific needs.',
    profileItems: [
      {
        title: 'Flexibility',
        logo: scalablility,
        description:
          'We offer the flexibility of transporting both full and partial loads, ensuring that your unique requirements are met efficiently.'
      },
      {
        title: 'End-to-End Service',
        logo: goodrating,
        description:
          'Enjoy peace of mind knowing that your goods are transported directly from collection to delivery without the use of hubs, ensuring minimal handling and faster delivery times.'
      },
      {
        title: 'Cost Efficiency',
        logo: moneysvg,
        description:
          'everaging the latest vehicles and cutting-edge technology, we are committed to keeping your costs down while maintaining the highest standards of service and reliability.'
      }
    ]
  },
  quoteRequest: {
    title: 'Request a Quote',
    fields: ['Your Name*', 'Your Email Address*', 'Contact Number*', 'From Location', 'To Location', 'Cargo/Parcel'],
    buttonText: 'Request a Quote'
  },

  whyChooseUs: {
    title: 'Why Choose Us',
    subtitle: 'Navigating the path forward',
    transportTruckImg: TransportTruck,
    features: [
      {
        title: 'High-Tech Vehicles',
        logo: truck,
        description:
          'High-Tech Vehicles Equipped with telematics and data analysis tools & reefer units with environment-Friendly latest and young fleet'
      },
      {
        logo: tracking,
        title: 'Real Time Track and Trace',
        description: 'GPS with real time tracking & temperature sensors and reporting'
      },
      {
        logo: saving,
        title: 'Best Services at Better Rates',
        description: 'By providing reliable and efficient services we strive to be of value to our clients.'
      },
      {
        logo: gardener,
        title: 'Skilled and Trained Team',
        description: 'Our team is our biggest asset. They build a culture of hard work'
      },
      {
        logo: wheel,
        title: 'Just-in-Time',
        description:
          'Our paramount duty is to deliver goods safely and in time. JIT service is our way to customer satisfaction.'
      },
      {
        logo: humanAssets,
        title: 'Redefining Transportation',
        description: 'Consistent training fosters growth and credibility for our dedicated team and drivers.'
      }
    ]
  },
  clientInfo: {
    title: 'Our Clients',
    clientlogos: [
      client1,
      client2,
      client3,
      client4,
      client5,
      client6,
      client7,
      client8,
      client9,
      client10,
      client11,
      client12,
      client13,
      client14,
      client15,
      client16,
      client17,
      client18,
      client19
    ]
  },
  awardsInfo: {
    title: 'Awards',
    certificates: [
      {
        name: 'certificate1',
        logo: certificate1
      },
      {
        name: 'certificate2',
        logo: certificate2
      },
      {
        name: 'certificate3',
        logo: certificate3
      },
      {
        name: 'certificate4',
        logo: certificate4
      },
      {
        name: 'certificate5',
        logo: certificate5
      }
    ]
  }
};

const HomePage = () => {
  const navigate = useNavigate();
  const [HomePageJSON, setHomePageJSON] = useState(defaultHomePageJSON);
  const [formdata, setFormdata] = useState({
    name: '',
    email: '',
    contact: '',
    from: '',
    to: '',
    cargo: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchHomePageJSON = async () => {
      try {
        const docRef = doc(db, 'jeetroadways', 'Homepage');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log('Document Home data:', docSnap.data());
          setHomePageJSON(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error in fetching data - ', error);
      }
    };

    fetchHomePageJSON();
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }

    if (error) {
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [formdata]);

  const handleChangeFormField = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    if (!formdata.name) {
      setError('Name is required');
      return false;
    }

    if (!formdata.email) {
      setError('Email is required');
      return false;
    }

    if (!formdata.contact) {
      setError('Contact is required');
      return false;
    }

    if (!formdata.from) {
      setError('From Location is required');
      return false;
    }

    if (!formdata.to) {
      setError('To Location is required');
      return false;
    }

    if (!formdata.cargo) {
      setError('Cargo is required');
      return false;
    }

    if (formdata.contact.length < 8 || formdata.contact.length > 15) {
      setError('Contact number should be in valid digits');
      return false;
    }

    // email validation with regex
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formdata.email)) {
      setError('Email is not valid');
      return false;
    }

    if (formdata.from === formdata.to) {
      setError('From location and To location should not be same');
      return false;
    }

    return true;
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const formData = {
      name: formdata.name,
      email: formdata.email,
      contact: formdata.contact,
      from: formdata.from,
      to: formdata.to,
      cargo: formdata.cargo
    };

    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    setLoading(true);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_HOME,
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log('FORM SUBMITTED SUCCESSFULLY.');
          setSuccess('Message Sent Successfully');
          setFormdata({
            name: '',
            email: '',
            subject: '',
            message: ''
          });
        },
        (error) => {
          console.log('ERROR IN SUBMITTING THE FORM.');
          setError('Failed to send message');
        }
      )
      .finally(() => {
        setLoading(false);
        setFormdata({
          name: '',
          email: '',
          contact: '',
          from: '',
          to: '',
          cargo: ''
        });
      });
  };

  const handleRedirect = (buttonName) => {
    if (buttonName === 'Contact Us') {
      navigate('/contact');
    } else if (buttonName === 'Our Services') {
      navigate('/services');
    }
  };

  const certificates =
    HomePageJSON?.awardsInfo?.certificates?.map((certificate) => {
      return certificate.logo;
    }) || [];

  return (
    <>
      <Helmet>
        <title>Jeet Roadways - Reliable Transportation Solutions</title>
        <meta
          name="description"
          content="Jeet Roadways specializes in transporting goods including palletized and uniquely shaped items, with a focus on cost efficiency, flexibility, and reliability. Jeet Roadways works with clients like Alkem Laboratories, Abbott Healthcare, Cipla, Godrej, Lupin, Pfizer, Sun Pharmaceuticals, and more."
        />
        <meta
          name="keywords"
          content="Jeet Roadways, transportation, goods, palletized items, full loads, partial loads, cost-efficient transportation, West Midlands"
        />
      </Helmet>
      <div className="homepage-container">
        <div className="home-page-header">
          <div className="image-container">
            <img src={HomePageJSON?.header?.image} alt="Jeet Roadways Truck" className="truck-image" />
          </div>
          <div className="homepage-content">
            <div className="content-title">{HomePageJSON?.header?.title}</div>
            <div className="content-motto">{HomePageJSON?.header?.subtitle}</div>
            <div className="content-buttons">
              <button onClick={() => handleRedirect(HomePageJSON?.header?.buttons[0])} className="contact-button">
                {HomePageJSON?.header?.buttons[0]}
              </button>
              <button onClick={() => handleRedirect(HomePageJSON?.header?.buttons[1])} className="services-button">
                {HomePageJSON?.header?.buttons[1]}
              </button>
            </div>
          </div>
        </div>

        <section className="company-profile-container">
          <section className="company-profile">
            <div>
              <h2 className="profile-title">{HomePageJSON?.companyProfile?.title}</h2>
              <div className="baseline-container">
                <div className="home-baseline"></div>
              </div>
            </div>
            <p className="profile-subtitle">{HomePageJSON?.companyProfile?.description}</p>
            <div className="profile-content">
              <div className="profile-image-container">
                <img src={HomePageJSON?.companyProfile?.image} alt="Company team" className="profile-image" />
              </div>
              <div className="profile-items">
                {HomePageJSON?.companyProfile?.profileItems.map((item, index) => (
                  <div className="profile-item" key={index}>
                    <div className="profile-icon-container">
                      <img src={item.logo} alt={item.title} className="profile-icon" />
                    </div>
                    <div className="profile-text">
                      <p className="profile-item-description">
                        <strong>{item.title} : </strong>
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <p className="profile-description">{HomePageJSON?.companyProfile?.descriptionFooter}</p>
          </section>
        </section>

        <section className="quote-request">
          <div className="quote-request-form-container">
            <h2 className="profile-title">{HomePageJSON?.quoteRequest?.title?.toUpperCase()}</h2>
            {/* <h3 className="quote-request-subtitle">{HomePageJSON?.quoteRequest?.subtitle?.toUpperCase()}</h3> */}

            <div className="baseline-container">
              <div className="home-baseline"></div>
            </div>
            {/* 'To Location', 'Cargo/Parcel'], */}
            <form className="quote-request-form" onSubmit={handleSubmitForm}>
              <input
                name="name"
                value={formdata.name}
                onChange={handleChangeFormField}
                type="text"
                required
                placeholder="Your Name*"
              />
              <input
                name="email"
                value={formdata.email}
                onChange={handleChangeFormField}
                type="email"
                placeholder="Your Email Address*"
                required
              />
              <input
                name="contact"
                value={formdata.contact}
                onChange={handleChangeFormField}
                type="number"
                placeholder="Contact Number*"
                required
              />
              <input
                name="from"
                value={formdata.from}
                onChange={handleChangeFormField}
                type="text"
                placeholder="From Location"
                required
              />
              <input
                name="to"
                value={formdata.to}
                onChange={handleChangeFormField}
                type="text"
                placeholder="To Location"
                required
              />
              <input
                name="cargo"
                value={formdata.cargo}
                onChange={handleChangeFormField}
                type="text"
                placeholder="Cargo/Parcel"
                required
              />

              {loading ? (
                'Loading...'
              ) : (
                <button type="submit" disabled={loading}>
                  <SendIcon />
                  {HomePageJSON?.quoteRequest?.buttonText?.toUpperCase()}
                </button>
              )}
              {success && <p className="homepage-success-message">{success}</p>}
              {error && <p className="homepage-error-message">{error}</p>}
            </form>
          </div>
        </section>

        <section className="why-choose-us-container">
          <div className="why-choose-us-header">
            <h2 className="profile-title">{HomePageJSON?.whyChooseUs?.title?.toUpperCase()}</h2>
            <div className="baseline-container">
              <div className="home-baseline"></div>
            </div>
            <h3 className="profile-subtitle">{HomePageJSON?.whyChooseUs?.subtitle?.toUpperCase()}</h3>
          </div>
          <div className="features">
            <div className="features-left">
              {HomePageJSON?.whyChooseUs?.features.slice(0, 3).map((item, index) => (
                <div className="profile-item" key={index}>
                  <div className="profile-text features-left-text">
                    <h3 className="feature-title">{item.title}</h3>
                    <p className="profile-item-description">{item.description}</p>
                  </div>
                  <div className="profile-icon-container">
                    <img src={item.logo} alt={item.title} className="profile-icon" />
                  </div>
                </div>
              ))}
            </div>
            <img
              src={HomePageJSON?.whyChooseUs?.transportTruckImg}
              alt="transport-truck"
              className="transport-truck"
              loading="lazy"
            />
            <div className="features-right">
              {HomePageJSON?.whyChooseUs?.features.slice(3, 6).map((item, index) => (
                <div className="profile-item" key={index}>
                  <div className="profile-icon-container">
                    <img src={item.logo} alt={item.title} className="profile-icon" />
                  </div>
                  <div className="profile-text">
                    <h3 className="feature-title">{item.title}</h3>
                    <p className="profile-item-description">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <ImageSlider
          image={HomePageJSON.clientInfo.clientlogos}
          clientLogo
          title={HomePageJSON?.clientInfo?.title?.toUpperCase()}
        />
        <ImageSlider
          image={certificates}
          showMultiImage
          coverImage
          title={HomePageJSON?.awardsInfo?.title?.toUpperCase()}
        />
      </div>
    </>
  );
};

export default HomePage;
