import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import emailjs from 'emailjs-com';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import bannerImgMobile from './assets/bannerImageMobile.png';
import bannerImgDesktop from './assets/bannerImageDesktop.png';
import mumbai_hq from './assets/mumbai_hq.png';
import chandigarh_hq from './assets/chandigarh_hq.png';
import location from './assets/Location.svg';
import call from './assets/Call.svg';
import email from './assets/Email.svg';
import truck_image1 from './assets/truck_image1.png';
import truck_image2 from './assets/truck_image2.png';
import truck_image3 from './assets/truck_image3.png';
import send from './assets/send.svg';

import './ContactUs.css';

const hq_data = [
  {
    image: mumbai_hq,
    address: 'BLDG.2 Unit -16, New Rajshree Ind. Premises, Near Hyde Park, Off Ghodbunder Road, Thane West- 400607',
    add_icon: location,
    contact: 'Mr.Sanjeev Parsekar - 8356888046 / 9509776899 \n 9920599965 / 9672899610 ',
    cont_icon: call,
    email: 'jeetroadways@gmail.com',
    email_icon: email,
    heading: 'MUMBAI HEAD OFFICE'
  },
  {
    image: chandigarh_hq,
    address: 'Near H.P. Gas Agency, makhan Mazra Road, Darua, Chandigarh - 160101',
    add_icon: location,
    contact: 'Mr.Dilip Babal - 9780982800 ',
    cont_icon: call,
    email: 'jeetroadways.chd@gmail.com',
    email_icon: email,
    heading: 'CHANDIGARH OFFICE'
  }
];

const locations = [
  {
    location: 'BHIWANDI OFFICE',
    address: 'Y-3, Shri Arihant Compound, Behind Tejasvi Hotel, Kalher village, Bhiwandi .Dist- Thane- 421302',
    contact: 'Mr.Sunil Kumar - 7977245480, Mr.Pradep - 9137843003'
  },

  {
    location: 'Ambala',
    address: 'Near Sharma Petrol Pump, Kuldeep Nagar,Nanakpura Ambala - 133004',
    contact: 'Mr. Raju - 09315410284, Mr .Bhaskar Ji - 9829919800'
  },

  {
    location: 'Baddi',
    address: 'Near Cipla Ltd. Village Malpur,Baddi - 173205',
    contact: 'Mr. Anil Kumar - 9816176936'
  },

  {
    location: 'NAGPUR',
    address: 'Near Vijay Automobiles, Amravati Road,Vaddhamana , Nagpur- 440023',
    contact: 'Mr. Sajjan - 8446957302'
  },

  {
    location: 'INDORE',
    address: 'Plot no. A14 Om Cgamber Godown No G-1, New Loha Mandi Dewas Naka, Indore - 452010',
    contact: 'Mr. Roshan Sharma - 09630268046'
  },

  {
    location: 'SILIGURI',
    address: 'Canal Road, opp 3rd no. Bridge Towards , Sharda Sishu Tirtha School, p.o Fulbari , Jalpaiguri - 735135',
    contact: 'Mr. Viswas sharma - 8016249566'
  },

  {
    location: 'GOA',
    address: 'Gokul No. 22/1 F1 , Prabhau Nagar, Curti ponda Goa - 403401',
    contact: 'Mr. Shivlal Sharma - 7620156060'
  },

  {
    location: 'Sikkim',
    address: '',
    contact: 'Mr. Pankaj Kumar- 7384383940'
  },

  {
    location: 'Ahmedabad',
    address: '',
    contact: 'Mr.Sajan Sharma - 9783904973 '
  },

  {
    location: 'Jaipur',
    address: 'Shop no, 49, Ganesh complex Siker Road, Jaipur - 302013',
    contact: 'Mr.Jitendra Singh - 9664232281'
  },

  {
    location: 'HARIDWAR / ROORKEE',
    address: 'Near Barrier No. 06, BHEL Road, Near Hanuman Temple, Bhadrabad, Haridwar, Uttranchal- 249402',
    contact: 'Mr. Manoj Goel - 9359510201'
  },

  {
    location: 'JAMMU',
    address: 'Opp, Veerbhumi park Budwani, Samba jammu - 184121',
    contact: 'Mr.Ajay kumar - 9797635135'
  },

  {
    location: 'Bengaluru',
    address: '',
    contact: 'Mr. Ashok Kumar - 8884846664'
  },

  {
    location: 'Guwahati',
    address: '',
    contact: 'Mr. Chetan Dudi - 8769037110'
  },

  {
    location: 'Dehradun',
    address: 'A-51, Transport Nagar, Saharanpur Road, Dehradun , Uttranchal - 248002',
    contact: 'Mr. Piyush Goel - 7906522961'
  },

  {
    location: 'Delhi',
    address: '3968, Roshanara Road, Delhi- 7',
    contact: '011 23826793 / 23978014'
  },

  {
    location: 'Ghaziabad',
    address: 'Mountgomary Compound, 6, Milestone Ghaziabad - 201001',
    contact: '0120 - 2626095 / 26+28092'
  },

  {
    location: 'Kashipur',
    address: 'Transport Nagar, Kashipur, Uttaranchal',
    contact: 'Mr. Jahid Khan - 08057639069'
  },

  {
    location: 'Pune',
    address: '803, Northen Towers, near Moshi Toll Plaza , Moshi Pune - 412105',
    contact: 'Mr. Tony - 9671968800'
  }
];

const defaultContactUsData = {
  title: 'Contact Us',
  bannerImgDesktop: bannerImgDesktop,
  bannerImgMobile: bannerImgMobile,
  hq_data: hq_data,
  locationLabel: 'Other Locations',
  locations: locations,
  contactTitle: 'SEND A MESSAGE',
  truckImages: [truck_image1, truck_image2, truck_image3]
};

function ContactUs() {
  const [data, setData] = React.useState(defaultContactUsData);
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    const fetchPageJSON = async () => {
      try {
        const docRef = doc(db, 'jeetroadways', 'ContactUsPage');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log('Contactus data:', docSnap.data());
          setData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error in fetching data - ', error);
      }
    };

    fetchPageJSON();
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }

    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [formData]);

  const validateForm = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!formData.name || formData.name.length < 3) {
      setError('Name is required with minimum 3 characters');
      return false;
    }

    if (!formData.email) {
      setError('Email is required');
      return false;
    }

    if (!formData.subject || formData.subject.length < 3) {
      setError('Subject is required with minimum 3 characters');
      return false;
    }

    if (!formData.message || (formData.message.length < 3 && formData.message.length > 100)) {
      setError('Message is required with max 100 characters');
      return false;
    }

    if (!emailRegex.test(formData.email)) {
      setError('Invalid email address');
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFromSubmit = (e) => {
    e.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    setLoading(true);

    const formdata = {
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formdata,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log('FORM SUBMITTED SUCCESSFULLY.');
          setSuccess('Message Sent Successfully');
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
          });
        },
        (error) => {
          console.log('ERROR IN SUBMITTING THE FORM.');
          setError('Failed to send message');
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  let bannerImg = data.bannerImgDesktop;

  if (window.innerWidth < 1024) {
    bannerImg = data.bannerImgMobile;
  }

  return (
    <>
      <Helmet>
        <title>Jeet Roadways | Contact Us</title>
        <meta
          name="description"
          content="Get in touch with Jeet Roadways. We have offices in Mumbai, Chandigarh, Nagpur, and more. Contact us for efficient transportation and cold chain services."
        />
        <meta
          name="keywords"
          content="Jeet Roadways, contact, offices, transportation, logistics, Mumbai, Chandigarh, Nagpur, Pune, Baddi, Goa, India"
        />
      </Helmet>
      <main className="contact_us_container">
        <section className="contact_banner_container">
          <img src={bannerImg} alt="contact us" className="contact_banner_left_image" />
          <h1 className="banner_h1">{data.title}</h1>
        </section>

        <section className="hq_data">
          {data.hq_data.map((data) => (
            <React.Fragment key={data.heading}>{hq_card(data)}</React.Fragment>
          ))}
        </section>

        <section className="locations_sector">
          <h1 className="other_location">{data.locationLabel}</h1>
          <div className="location_container">
            {data.locations.map((data, index) => (
              <React.Fragment key={index}>{location_card(data)}</React.Fragment>
            ))}
          </div>
        </section>

        <section className="email_us">
          <h1 className="send_message">{data.contactTitle}</h1>
          <div className="email_us_content_container">
            <aside className="email_us_image_container">
              <img
                src={data.truckImages[0]}
                alt="jeetroadways truck running on the road"
                className="span1 email_us_image"
              />
              <div className="email_us_two_image">
                <img
                  src={data.truckImages[1]}
                  className="email_us_image"
                  alt="jeetroadways truck running on the road"
                />
                <img src={data.truckImages[2]} className="email_us_image" alt="jeetroadways truck" />
              </div>
            </aside>

            <form onSubmit={handleFromSubmit} className="email_us_form_container">
              <div className="mb-10">
                <label htmlFor="name">
                  Name <span style={{ color: '#FF6B34' }}>*</span>
                </label>{' '}
                <br />
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="email_us_input"
                  id="name"
                  placeholder="Name"
                />
              </div>

              <div className="mb-10">
                <label htmlFor="email">
                  Email <span style={{ color: '#FF6B34' }}>*</span>
                </label>{' '}
                <br />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="email_us_input"
                  id="email"
                  placeholder="Email"
                />
              </div>

              <div className="mb-10">
                <label htmlFor="subject">
                  Subject <span style={{ color: '#FF6B34' }}>*</span>
                </label>{' '}
                <br />
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="email_us_input"
                  id="subject"
                  placeholder="Subject"
                />
              </div>

              <div className="mb-10">
                <label htmlFor="message">
                  Message <span style={{ color: '#FF6B34' }}>*</span>
                </label>{' '}
                <br />
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  type="text"
                  className="email_us_input"
                  id="message"
                  placeholder="Message"
                />
              </div>

              <button type="submit" className="submit_button">
                <img src={send} alt="" />
                <p style={{ color: 'white' }}>Submit</p>
              </button>

              {success && <p className="contact_us-success-message text-center">{success}</p>}
              {error && <p className="contact_us-error-message text-center">{error}</p>}
            </form>
          </div>
        </section>
      </main>
    </>
  );
}

export default ContactUs;

function hq_card(data) {
  return (
    <section className="hq_card_container">
      <aside className="hq_card_image">
        <img src={data.image} alt="" />
      </aside>
      <aside className="hq_card_content">
        <div className="hq_card_heading">
          <h1 className="hq_card_heading_text">{data.heading}</h1>
        </div>

        <div className="hq_card_data">
          <img src={data.add_icon} alt="" />
          <span>{data.address}</span>
        </div>

        <div className="hq_card_data">
          <img src={data.cont_icon} alt="" />
          <span>{data.contact}</span>
        </div>

        <div className="hq_card_data">
          <img src={data.email_icon} alt="" />
          <span>{data.email}</span>
        </div>
      </aside>
    </section>
  );
}

function location_card(data) {
  return (
    <div title={data.location} className="location_card_container">
      <h4 className="location">{data.location.toUpperCase()}</h4>
      {data.address && <span className="location_detail">{data.address}</span>}
      <span className="location_detail">{data.contact}</span>
    </div>
  );
}
