import React from 'react';
import '../Styles/footer.css';
import arrow from '../assets/Upper_Arrow.png';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  const [copySuccess, setCopySuccess] = React.useState('');

  React.useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess('');
      }, 3000);
    }
  }, [copySuccess]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const copyToClipboard = async (message) => {
    try {
      await navigator.clipboard.writeText(message);
      setCopySuccess('Copied to clipboard!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <section className="footer-section">
          <div className="footer-heading">
            <h3>Mumbai Head Office</h3>
          </div>
          <div className="flex items-center gap-2">
            <i className="fa fa-map-marker icon" style={{ fontSize: '24px' }} aria-hidden="true"></i>{' '}
            <p
              onClick={() =>
                copyToClipboard(
                  'BLDG.2 Unit -16, New Rajshree Ind. Premises, Near Hyde Park, Off Ghodbunder Road, Thane West- 400607'
                )
              }
            >
              BLDG.2 Unit -16, New Rajshree Ind. Premises, Near Hyde Park, Off Ghodbunder Road, Thane West- 400607
            </p>
          </div>
          <div className="flex gap-2">
            <i className="fa fa-mobile icon" style={{ fontSize: '28px' }} aria-hidden="true"></i>
            <p>
              <strong>Mr. Sanjeev Parsekar</strong> -{' '}
              <span onClick={() => copyToClipboard('8356888046')}>8356888046</span> /{' '}
              <span onClick={() => copyToClipboard('9509776899')}>9509776899</span> /
              <span onClick={() => copyToClipboard('9672899610')}>9672899610</span> /{' '}
              <span onClick={() => copyToClipboard('9920599965')}>9920599965</span>
            </p>
          </div>
          <div className="flex gap-2">
            <i className="fa fa-envelope icon" style={{ fontSize: '18px' }} aria-hidden="true"></i>
            <a href="mailto:jeetroadways@gmail.com">jeetroadways@gmail.com</a>
          </div>{' '}
        </section>
        <section aria-labelledby="Chandigarh-contact-info" className="footer-section">
          <div aria-label="Chandigarh-contact-info" className="footer-heading">
            <h3>Chandigarh Office</h3>
          </div>
          <div className="flex items-center gap-2">
            <i className="fa fa-map-marker icon" style={{ fontSize: '24px' }} aria-hidden="true"></i>{' '}
            <p
              aria-label="Chandigarh office address of jeetroadways"
              onClick={() => copyToClipboard('Near H.P. Gas Agency, makhan Mazra Road, Darua, Chandigarh - 160101')}
            >
              Near H.P. Gas Agency, makhan Mazra Road, Darua, Chandigarh - 160101
            </p>
          </div>
          <div className="flex gap-2">
            <i className="fa fa-mobile icon" style={{ fontSize: '28px' }} aria-hidden="true"></i>
            <p>
              <strong>Mr. Dilip Babal</strong> - <span onClick={() => copyToClipboard('9780982800')}>9780982800</span>
            </p>
          </div>
          <div className="flex gap-2">
            <i className="fa fa-envelope icon" style={{ fontSize: '18px' }} aria-hidden="true"></i>
            <a href="mailto:jeetroadways.chd@gmail.com">jeetroadways.chd@gmail.com</a>
          </div>
        </section>
        <section aria-labelledby="social-media" className="footer-section">
          <div id="social-media" className="footer-heading">
            <h3>Social Media</h3>
          </div>
          <div className="social-icons">
            <a
              aria-label="Visit our Facebook page"
              href="https://www.facebook.com/people/Jeet-Roadways/100057627931772/?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-facebook icon"></i>
            </a>
            <a
              aria-label="Visit our linkedin profile"
              href="https://www.linkedin.com/company/jeetroadways/about/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-linkedin icon"></i>
            </a>
          </div>
        </section>
        <section aria-labelledby="jeetroadways footer navigation" className="footer-section">
          <div aria-label="footer nav links" className="footer-heading">
            <h3>Menu</h3>
          </div>
          <ul className="menu-items" aria-label="Footer Navigation">
            {['Home', 'About Us', 'Services', 'Contact Us', 'Gallery'].map((navItem) => (
              <li className="menu-item" key={navItem}>
                <NavLink
                  onClick={scrollToTop}
                  to={`/${navItem.toLowerCase().split(' ')[0]}`}
                  className="footer-navigation-link"
                >
                  {navItem}
                </NavLink>
              </li>
            ))}
          </ul>
        </section>
      </div>
      {copySuccess && <p className="text-center">{copySuccess}</p>}
      <div className="hr-container">
        <img src={arrow} alt="Footer-Center-Img" className="hr-image" onClick={scrollToTop} />
      </div>

      <p className="footer-end text-center">
        © 2024 Jeet Roadways - Developed By{' '}
        <strong aria-label="design and developed by the veritech inc.">Veritech Inc.</strong>
      </p>
    </footer>
  );
};

export default Footer;
