import React from 'react';
import './services.css';
import { Helmet } from 'react-helmet-async';
import bgImageDesktop from './assets/bgImageDesktop.png';
import bgImageMobile from './assets/bgImageMobile.png';
import serviceImage1 from './assets/services_1.png';
import serviceImage2 from './assets/services_2.png';
import serviceImage3 from './assets/services_3.png';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const defaultServicePageJSON = {
  title: 'Our Services',
  bgImageDesktop: bgImageDesktop,
  bgImageMobile: bgImageMobile,
  services: [
    {
      title: 'Trucking and Transportation',
      details: [
        'We provide Trucks, Trailers, Containers, and Canters with various load carrying capacities, along with brokerage transportation services.',
        'We offer total transport and cargo solutions, including Transportation, Logistics Services, and Packing & Moving Services, catering to business needs across India.',
        'Currently, we handle the total movement of cargo from various locations nationwide.'
      ],
      imageUrl: serviceImage1
    },
    {
      title: 'Cold Chain Transportation',
      details: [
        'Jeet Roadways offers extensive cold chain services with Reefer trucks (refrigerated/freezer) for temperature controlled transportation.',
        'Indulged in majorly Pharmaceutical APIs, Formulations, Vaccines, Injectables and other raw materials and finished goods catering to pharma industry needs.',
        'The quality of products are maintained through timely delivery in a temperature controlled and secured truck.'
      ],
      imageUrl: serviceImage2
    },
    {
      title: 'Warehouses',
      details: [
        'We exhibit a well secured and spacious warehouse and godown facility in selected locations permitting intact pollution free storage, impervious to any kind of damage or contamination.',
        'We are fully equipped to store bulk cargo including other requirements such as Break bulk as well as cargo that is in units, packages, bags and much more.',
        'Furthermore, we also offer Third party logistics services at our godowns within our network.'
      ],
      imageUrl: serviceImage3
    },
    {
      title: 'Automated Tracking',
      details: [
        'Our efficient transportation fleets and state-of-the-art facilities are bolstered by highly dedicated and motivated staff.',
        'We utilize the latest computer technology, including a computerized monitoring system, to ensure increased efficiency and timely delivery of goods.',
        'Skilled manpower, telecommunication, and online computer networks enable us to optimize every detail of the transportation chain, focusing on routing, documentation, procedures, and timing.'
      ],
      imageUrl: serviceImage1
    },
    {
      title: 'Comprehensive Logistic Solutions',
      details: [
        'Jeet Roadways operates through a vast network of 26 owned branches and associated offices, servicing over 100 destinations across North India.',
        'We possess a fleet of over 500 owned and attached trucks and 5,000 tonnes capacity warehouses strategically located in various cities, 100+ destinations across India to meet diverse clientele requirements.'
      ],
      imageUrl: serviceImage3
    }
  ]
};

const Services = () => {
  const [data, setData] = React.useState(defaultServicePageJSON);

  React.useEffect(() => {
    const fetchPageJSON = async () => {
      try {
        const docRef = doc(db, 'jeetroadways', 'ServicesPage');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log('Services data:', docSnap.data());
          setData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error in fetching data - ', error);
      }
    };

    fetchPageJSON();
  }, []);

  return (
    <>
      <Helmet>
        <title>Jeet Roadways | Services</title>
        <meta
          name="description"
          content="Jeet Roadways offers trucking, cold chain, and warehouse services, catering to various industries including pharmaceuticals, FMCG, and textiles."
        />
        <meta
          name="keywords"
          content="Jeet Roadways, trucking, transportation, cold chain services, warehouse, logistics, pharmaceuticals, FMCG, textiles, temperature-controlled trucks"
        />
      </Helmet>
      <main className="services">
        <section className="services__hero">
          <img src={window.innerWidth > 1024 ? data.bgImageDesktop : data.bgImageMobile} alt="services" />
          <h1 className="services__hero-title">{data.title}</h1>
        </section>
        <section className="services__list">
          {data.services.map((service, i) => (
            <div className="services__item-wrapper" key={i}>
              <Service serviceNum={i + 1} service={service} />
            </div>
          ))}
        </section>
      </main>
    </>
  );
};

const Service = ({ service, serviceNum }) => {
  return (
    <>
      <aside className="service__image">
        <img src={service.imageUrl} alt="service" />
      </aside>
      <aside className="service__details">
        <h4 className="service__title">{service.title}</h4>
        <ul className="service__list">
          {service.details.map((detail, i) => (
            <li className="service__list-item" key={i}>
              {detail}
            </li>
          ))}
        </ul>
      </aside>
    </>
  );
};

export default Services;
