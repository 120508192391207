import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './Gallery.css';
import GalleryImage from '../../assets/Gallery_Banner.png';
import gallery_1 from './Gallery_Assets/gallery_1.png';
import gallery_2 from './Gallery_Assets/gallery_2.png';
import gallery_3 from './Gallery_Assets/gallery_3.png';
import gallery_4 from './Gallery_Assets/gallery_4.png';
import gallery_5 from './Gallery_Assets/gallery_5.png';
import gallery_6 from './Gallery_Assets/gallery_6.png';
import gallery_7 from './Gallery_Assets/gallery_7.png';
import gallery_8 from './Gallery_Assets/gallery_8.png';
import gallery_9 from './Gallery_Assets/gallery_9.png';
import gallery_10 from './Gallery_Assets/gallery_10.png';
import gallery_11 from './Gallery_Assets/gallery_11.png';
import gallery_12 from './Gallery_Assets/gallery_12.png';
import gallery_13 from './Gallery_Assets/gallery_13.png';
import gallery_14 from './Gallery_Assets/gallery_14.png';
import gallery_15 from './Gallery_Assets/gallery_15.png';
import gallery_16 from './Gallery_Assets/gallery_16.png';
import awards_1 from './Gallery_Assets/awards_1.png';
import awards_2 from './Gallery_Assets/awards_2.png';
import awards_3 from './Gallery_Assets/awards_3.png';
import awards_4 from './Gallery_Assets/awards_4.png';
import awards_5 from './Gallery_Assets/awards_5.png';
import ImageSlider from '../../Components/carousel/carousel';
import LightGalleryWrapper from '../../Components/lightgallery/LightGalleryWrapper';

const defaultGelleryJSON = {
  title: 'Gallery',
  backgroundImage: GalleryImage,
  label: 'Gallery',
  awardTitle: 'Awards',
  awards: [awards_1, awards_2, awards_3, awards_4, awards_5],
  images: [
    gallery_1,
    gallery_2,
    gallery_3,
    gallery_4,
    gallery_5,
    gallery_6,
    gallery_7,
    gallery_8,
    gallery_9,
    gallery_10,
    gallery_11,
    gallery_12,
    gallery_13,
    gallery_14,
    gallery_15,
    gallery_16
  ]
};

const Gallery = () => {
  const [data, setData] = useState(defaultGelleryJSON);
  const [currentPage, setCurrentPage] = useState(1);
  const [awardsStartIndex, setAwardsStartIndex] = useState(0);
  const imagesPerPage = 15;
  const awardsPerPage = 4;

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = data.images.slice(indexOfFirstImage, indexOfLastImage);

  const endAwardsIndex = awardsStartIndex + awardsPerPage;
  const currentAwards = data.awards.slice(awardsStartIndex, endAwardsIndex);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  React.useEffect(() => {
    const fetchPageJSON = async () => {
      try {
        const docRef = doc(db, 'jeetroadways', 'GalleryPage');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log('Gallery data:', docSnap.data());
          setData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error in fetching data - ', error);
      }
    };

    fetchPageJSON();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Helmet>
        <title>Jeet Roadways | Gallery</title>
        <meta
          name="description"
          content="Jeet Roadways Gallery page. Check out our gallery for images of our trucks and awards."
        />
      </Helmet>
      <main className="gallery-page">
        <section className="gallery-banner">
          <img src={data.backgroundImage} alt="jeetroadways gallery page" />
          <div className="banner-text">{data.title}</div>
        </section>
        <section>
          <section className="gallery-header">
            <h2 id="gallery">{data.label}</h2>
          </section>
          <section className="gallery-grid">
            <LightGalleryWrapper images={currentImages} />
          </section>
          <section className="pagination">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              &lt;
            </button>
            <button onClick={() => handlePageChange(1)} className={currentPage === 1 ? 'active' : ''}>
              1
            </button>
            <button onClick={() => handlePageChange(2)} className={currentPage === 2 ? 'active' : ''}>
              2
            </button>
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === 2}>
              &gt;
            </button>
          </section>

          <ImageSlider image={currentAwards} title={data.awardTitle} />
        </section>
      </main>
    </>
  );
};

export default Gallery;
