import React, { useRef, useEffect } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const LightGalleryWrapper = ({ images }) => {
  // const galleryRef = useRef(null);

  // useEffect(() => {
  //   // Destroy LightGallery instance on unmount to avoid memory leaks
  //   const gallery = galleryRef.current;
  //   return () => {
  //     if (gallery) {
  //       gallery.destroy(true);
  //     }
  //   };
  // }, []);

  return (
    <LightGallery
      // reference={galleryRef}
      speed={500}
      plugins={[lgThumbnail, lgZoom]}
      closable={true}
      autoplay={false}
      mode="lg-fade"
    >
      {images.map((image, index) => (
        <a className="lightgallery-item" href={image} key={index}>
          <img src={image} alt={`Gallery Item ${index + 1}`} className="gallery-item" />
          <div className="search-icon">
            <i className="fa fa-search"></i>
          </div>
        </a>
      ))}
    </LightGallery>
  );
};

export default LightGalleryWrapper;
