import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Outlet } from 'react-router';
import reportWebVitals from './reportWebVitals';
import HomePage from './Page/HomePage/HomePage';
import Header from './Components/Header';
import Footer from './Components/Footer';
import AboutUs from './Page/AboutUs/AboutUs';
import Gallery from './Page/Gallery/Gallery';
import ContactUs from './Page/ContactUs/ContactUs';
import Services from './Page/Services/Services';
import './index.css';
import ProtectedRoute from './Components/ProtectedRoute';
import { lazy, Suspense } from 'react';
import Spinner from './Components/Spinner';

const Admin = lazy(() => import('./Page/Admin/Admin'));
const AdminLogin = lazy(() => import('./Page/Admin/AdminLogin'));

const LandingPageLayout = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPageLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/home',
        element: <HomePage />
      },
      {
        path: '/about',
        element: <AboutUs />
      },
      {
        path: '/services',
        element: <Services />
      },
      {
        path: '/contact',
        element: <ContactUs />
      },
      {
        path: '/gallery',
        element: <Gallery />
      },
      {
        path: '/admin',
        element: (
          <Suspense fallback={<Spinner />}>
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          </Suspense>
        )
      },
      {
        path: '/admin-login',
        element: (
          <Suspense fallback={<Spinner />}>
            <AdminLogin />
          </Suspense>
        )
      },
      {
        path: '*',
        element: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '96vh',
              fontSize: '2rem'
            }}
          >
            404 : Page Not Found
          </div>
        )
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
