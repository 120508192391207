import React, { useState, useEffect } from 'react';
import logo from '../assets/Jeet_Roadways_Logo.png';
import '../Styles/header.css';
import { NavLink } from 'react-router-dom';
// import { Navigate, NavLink } from 'react-router-dom';

const Header = () => {
  const [activeNav, setActiveNav] = useState('');
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavClick = (navName) => {
    setActiveNav(navName);
    if (isMobile) {
      setIsPanelOpen(false);
    }
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <header className="header">
      <div className="logo-container">
        <NavLink to="/" onClick={() => handleNavClick('/')}>
          <img src={logo} alt="Jeet Roadways" className="logo" />
        </NavLink>
      </div>
      {isPanelOpen ? (
        <div className="nav-toggle close" onClick={togglePanel}>
          &#x2715;
        </div>
      ) : (
        <div className="nav-toggle open" onClick={togglePanel}>
          &#9776;
        </div>
      )}
      <nav className={`nav ${isPanelOpen ? 'open' : ''}`}>
        <NavLink
          to="/"
          className={`nav-item ${activeNav === 'home' ? 'active' : ''}`}
          onClick={() => handleNavClick('/')}
        >
          HOME
        </NavLink>
        <NavLink
          to="/about"
          className={`nav-item ${activeNav === 'about' ? 'active' : ''}`}
          onClick={() => handleNavClick('/about')}
        >
          ABOUT US
        </NavLink>
        <NavLink
          to="/services"
          className={`nav-item ${activeNav === 'services' ? 'active' : ''}`}
          onClick={() => handleNavClick('/services')}
        >
          SERVICES
        </NavLink>
        <NavLink
          to="/contact"
          className={`nav-item ${activeNav === 'contact' ? 'active' : ''}`}
          onClick={() => handleNavClick('/contact')}
        >
          CONTACT US
        </NavLink>
        <NavLink
          to="/gallery"
          className={`nav-item ${activeNav === 'gallery' ? 'active' : ''}`}
          onClick={() => handleNavClick('/gallery')}
        >
          GALLERY
        </NavLink>
        <a href="#track" className="track-package-button">
          Track Package
        </a>
      </nav>
      {isMobile && isPanelOpen && <div className="overlay" onClick={togglePanel}></div>}
    </header>
  );
};

export default Header;
