import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css';

const ImageSlider = ({ title, image, backgroundImage, showBorder = false, coverImage = false, clientLogo = false }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: clientLogo ? 3 : 1
        }
      }
    ]
  };

  const getHeight = () => {
    const windowWidth = window.innerWidth;
    if (clientLogo && windowWidth <= 768) return '50px';
    if (clientLogo && windowWidth > 768) return '130px';

    return '246px';
  };

  const imageHeight = getHeight();

  return (
    <section className={`slider-container ${!backgroundImage ? 'slider-bg' : ''} `}>
      {/* {backgroundImage && <img className="slider-background-image" src={backgroundImage} alt="slider" />} */}

      <div className="slider-heading">
        <h3>{title}</h3>
      </div>
      <div>
        <Slider {...settings}>
          {image.map((image, index) => (
            <div key={index}>
              <div
                className={`image-wrapper ${showBorder ? 'image-slider-border' : ''}`}
                style={{ height: imageHeight }}
              >
                <img
                  loading="lazy"
                  src={image}
                  alt={`Slide ${index + 1}`}
                  style={{ width: '100%', height: imageHeight, objectFit: coverImage ? 'cover' : 'contain' }}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ImageSlider;
