import React, { useEffect, useState } from 'react';
import './map.css';
import mapSrc from './map_final.jpg';

const MapWithTooltips = ({ cities }) => {
  const minLongitude = 68.0;
  const maxLongitude = 97.0;
  const minLatitude = 8.0;
  const maxLatitude = 37.0;

  const [mapDimensions, setMapDimensions] = useState({ width: 0, height: 0 });

  const updateMapDimensions = () => {
    const element = document.querySelector('.map-container');
    if (element) {
      const positionInfo = element.getBoundingClientRect();
      setMapDimensions({ width: positionInfo.width, height: positionInfo.height });
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = mapSrc;
    img.onload = () => {
      updateMapDimensions();
    };

    window.addEventListener('resize', updateMapDimensions);

    return () => {
      window.removeEventListener('resize', updateMapDimensions);
    };
  }, []);

  const calculatePosition = (latitude, longitude) => {
    const { width: mapWidth, height: mapHeight } = mapDimensions;
    const x = ((longitude - minLongitude) / (maxLongitude - minLongitude)) * mapWidth;
    const y = ((maxLatitude - latitude) / (maxLatitude - minLatitude)) * mapHeight;
    return { left: `${x}px`, top: `${y}px` };
  };

  return (
    <div className="map-container">
      <img src={mapSrc} alt="Map of India" className="map-image" />
      {cities.map((city, index) => (
        <div key={index} className="city-point" style={calculatePosition(city.latitude, city.longitude)}>
          <div className="tooltip">
            {city.title && <div className="tooltip-title">{city.title}</div>}
            {city.address && <div className="tooltip-address">{city.address}</div>}
            {city.spocs && (
              <>
                {city.spocs.map((spoc, i) => (
                  <div key={i} className="tooltip-spoc">
                    {spoc.name && <strong>{spoc.name}: </strong>}
                    {spoc.contacts && (
                      <span className="tooltip-contacts">
                        {Array.isArray(spoc.contacts) ? spoc.contacts.join(" / ") : spoc.contacts}
                      </span>
                    )}
                  </div>
                ))}
              </>
            )}
            {city.email && (
              <>
                {Array.isArray(city.email) ? (
                  city.email.map((email, i) => (
                    <div key={i} className="tooltip-email">
                      {email}
                    </div>
                  ))
                ) : (
                  <div className="tooltip-email">{city.email}</div>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MapWithTooltips;