// src/Page/AboutUs/AboutUs.js
import React, { useState } from 'react';
import './AboutUs.css';
import { Helmet } from 'react-helmet-async';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import aboutUsBlack from '../AboutUs/AboutUs4.png';
import csr from '../AboutUs/AboutUSBg.png';
import image1 from '../AboutUs/CSR-159.png';
import image2 from '../AboutUs/CSR-160.png';
import image3 from '../AboutUs/CSR-161.png';
import image4 from '../AboutUs/CSR-162.png';
import image5 from '../AboutUs/CSR-163.png';
import image6 from '../AboutUs/CSR-164.png';
import image7 from '../AboutUs/CSR-165.png';
import image8 from '../AboutUs/CSR-166.png';
import image9 from '../AboutUs/CSR-167.png';
import image10 from '../AboutUs/CSR-169.png';
import image11 from '../AboutUs/CSR-170.png';

import client1 from '../../assets/client-118.png';
import client2 from '../../assets/client-119.png';
import client3 from '../../assets/client-121.png';
import client4 from '../../assets/client-123.png';
import client5 from '../../assets/client-124.png';
import client6 from '../../assets/client-125.png';
import client7 from '../../assets/client-126.png';
import client8 from '../../assets/client-127.png';
import client9 from '../../assets/client-128.png';
import client10 from '../../assets/client-129.png';
import client11 from '../../assets/client-130.png';
import client12 from '../../assets/client-131.png';
import client13 from '../../assets/client-132.png';
import client14 from '../../assets/client-133.png';
import client15 from '../../assets/client-3.png';
import client16 from '../../assets/client-5.png';
import client17 from '../../assets/client-6.png';
import client18 from '../../assets/client-7.png';
import client19 from '../../assets/client-8.png';
import ImageSlider from '../../Components/carousel/carousel';
import MapWithTooltips from '../../Components/map/map';

import aboutus1 from './aboutus-1.png';
import aboutus2 from './aboutus-2.png';
import aboutus3 from './aboutus-3.png';

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11];
const clients = [
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client8,
  client9,
  client10,
  client11,
  client12,
  client13,
  client14,
  client15,
  client16,
  client17,
  client18,
  client19
];

const defaultAboutUsJSON = {
  title: 'About Us',
  backgroundImage: aboutUsBlack,
  otherImages: [aboutus1, aboutus2, aboutus3],
  aboutUsTitle: 'WHO WE ARE',
  aboutUsContent: [
    'Jeet Roadways was formed about 30 years ago by our founder Shri Sagarmal Babal. It was established as a small unit and gradually the company has grown every year by age and experience to the level from where it can handle any new challenge. The Company has implemented all the modern advancements and technologies to ensure excellent client satisfaction.',
    "It's the trust our clients have shown all through the journey to make us stand as a stalwart in the transport industry, boasting a substantial fleet of both owned and attached Pharma Compliant Reefer vehicles. Our operational reach extends throughout the western, central, eastern and southern regions of India.",
    'While our roots lie in serving the pharmaceutical sector, our client base encompasses diverse industries, including FMCG and textiles. Over the years, we have honed our expertise, positioning ourselves as a premier provider of high-quality Pharma-compliant fleet services.',
    'At Jeet Roadways, we cherish our satisfied customers as our most valuable assets. Treating each customer and their transportation needs with utmost priority is ingrained in our ethos. We are dedicated to providing unparalleled service, characterized by punctuality, reliability, and customer satisfaction.'
  ],
  clients: clients,
  mapSectionTitle: 'WE SERVICE ACROSS INDIA',
  mapSectionContent:
    'Jeet Roadways operates through a vast network of 26 owned branches and associated offices, servicing over 100 destinations across India.',
  mapLocations: [
    {
      name: 'Delhi',
      latitude: 28.7041,
      longitude: 77.1025,
      title: 'Delhi',
      address: '3968, Roshanara Road, Delhi- 7',
      spocs: [
        {
          name: null,
          contacts: ['011 23826793', '23978014']
        }
      ]
    },
    {
      name: 'Chandigarh',
      latitude: 30.73,
      longitude: 76.78,
      title: 'Chandigarh Office',
      address: 'Near H.P. Gas Agency, makhan Mazra Road,Darua, Chandigarh - 160101',
      spocs: [
        {
          name: 'Mr.Dilip Babal',
          contacts: ['9780982800']
        }
      ],
      email: 'jeetroadways.chd@gmail.com'
    },
    {
      name: 'Mumbai',
      latitude: 19.08,
      longitude: 72.89,
      title: 'Mumbai Head Office',
      address: 'Near H.P. Gas Agency, makhan Mazra Road,Darua, Chandigarh - 160101',
      spocs: [
        {
          name: 'Mr.Sanjeev Parsekar',
          contacts: ['8356888046', '9509776899']
        },
        {
          name: null,
          contacts: ['9920599965', '9672899610']
        }
      ],
      email: 'jeetroadways@gmail.com'
    },
    {
      name: 'Bhiwandi',
      latitude: 19.3025562,
      longitude: 73.0588072,
      title: 'Bhiwandi Office',
      address: 'Y-3, Shri Arihant Compound, Behind Tejasvi Hotel, Kalher village, Bhiwandi Dist- Thane- 421302',
      spocs: [
        {
          name: 'Mr.Sunil Kumar',
          contacts: ['7977245480']
        },
        {
          name: 'Mr.Pradep',
          contacts: ['9137843003']
        }
      ],
      email: 'jeetroadways@gmail.com'
    },
    {
      name: 'Ambala',
      latitude: 30.3843674,
      longitude: 76.770421,
      title: 'Ambala',
      address: 'Near Sharma Petrol Pump, Kuldeep Nagar, Nanakpura Ambala – 133004',
      spocs: [
        {
          name: 'Mr.Raju',
          contacts: ['09315410284']
        },
        {
          name: 'Mr. Bhaskar Ji',
          contacts: ['9829919800']
        }
      ],
      email: 'jeetroadways@gmail.com'
    },
    {
      name: 'Baddi',
      latitude: 30.9763026,
      longitude: 76.7674001,
      title: 'Baddi',
      address: 'Near Cipla Ltd. Village Malpur, Baddi – 173205',
      spocs: [
        {
          name: 'Mr. Anil Kumar',
          contacts: ['9816176936']
        }
      ]
    },
    {
      name: 'Nagpur',
      latitude: 21.1498134,
      longitude: 79.0820556,
      title: 'Nagpur',
      address: 'Near Vijay Automobiles, Amravati Road, Vaddhamana , Nagpur- 440023',
      spocs: [
        {
          name: 'Mr. Sajjan',
          contacts: ['8446957302']
        }
      ]
    },
    {
      name: 'Indore',
      latitude: 22.7203616,
      longitude: 75.8681996,
      title: 'Indore',
      address: 'Plot no. A14 Om Cgamber Godown No G-1, New Loha Mandi Dewas Naka, Indore – 452010',
      spocs: [
        {
          name: 'Mr. Roshan Sharma',
          contacts: ['09630268046']
        }
      ]
    },
    {
      name: 'Sliliguri',
      latitude: 26.7164127,
      longitude: 88.4309916,
      title: 'Sliliguri',
      address: 'Canal Road, opp 3rd no. Bridge Towards , Sharda Sishu Tirtha School, p.o Fulbari , Jalpaiguri – 735135',
      spocs: [
        {
          name: 'Mr. Viswas sharma',
          contacts: ['8016249566']
        }
      ]
    },
    {
      name: 'Goa',
      latitude: 15.3004543,
      longitude: 74.0855134,
      title: 'Goa',
      address: 'Gokul No. 22/1 F1 , Prabhau Nagar, Curti ponda Goa – 403401',
      spocs: [
        {
          name: 'Mr. Shivlal Sharma',
          contacts: ['7620156060']
        }
      ]
    },
    {
      name: 'Sikkim',
      latitude: 27.601029,
      longitude: 88.4541364,
      title: 'Sikkim',
      address: null,
      spocs: [
        {
          name: 'Mr. Pankaj Kumar',
          contacts: ['7384383940']
        }
      ]
    },
    {
      name: 'Ahmedabad',
      latitude: 23.0215374,
      longitude: 72.5800568,
      title: 'Ahmedabad',
      address: null,
      spocs: [
        {
          name: 'Mr.Sajan Sharma',
          contacts: ['9783904973']
        }
      ]
    },
    {
      name: 'Jaipur',
      latitude: 26.9154576,
      longitude: 75.8189817,
      title: 'Jaipur',
      address: 'Shop no, 49, Ganesh complex Siker Road, Jaipur – 302013',
      spocs: [
        {
          name: 'Mr.Sajan Sharma',
          contacts: ['9783904973']
        }
      ]
    },
    {
      name: 'HARIDWAR / ROORKEE',
      latitude: 29.87,
      longitude: 77.8902124,
      title: 'HARIDWAR / ROORKEE',
      address: 'Near Barrier No. 06, BHEL Road, Near Hanuman Temple, Bhadrabad, Haridwar, Uttranchal- 249402',
      spocs: [
        {
          name: 'Mr. Manoj Goel',
          contacts: ['9359510201']
        }
      ]
    },
    {
      name: 'Jammu',
      latitude: 32.72,
      longitude: 74.85,
      title: 'Jammu',
      address: 'Opp, Veerbhumi park Budwani, Samba jammu – 184121',
      spocs: [
        {
          name: 'Mr.Ajay kumar',
          contacts: ['9797635135']
        }
      ]
    },
    {
      name: 'Bengaluru',
      latitude: 12.98,
      longitude: 77.59,
      title: 'Bengaluru',
      address: null,
      spocs: [
        {
          name: 'Mr.Ajay kumar',
          contacts: ['9343935357']
        }
      ]
    },
    {
      name: 'Guwahati',
      latitude: 26.18,
      longitude: 91.75,
      title: 'Guwahati',
      address: null,
      spocs: [
        {
          name: 'Mr. Chetan Dudi',
          contacts: ['8769037110']
        }
      ]
    },
    {
      name: 'DEHRADUN',
      latitude: 30.33,
      longitude: 78.04,
      title: 'DEHRADUN',
      address: 'A-51, Transport Nagar, Saharanpur Road, Dehradun , Uttranchal - 248002',
      spocs: [
        {
          name: 'Mr. Piyush Goel',
          contacts: ['7906522961']
        }
      ]
    },
    {
      name: 'Ghaziabad',
      latitude: 28.77,
      longitude: 77.46,
      title: 'Ghaziabad',
      address: 'Mountgomary Compound, 6, Milestone Ghaziabad- 201001',
      spocs: [
        {
          name: null,
          contacts: ['0120 – 2626095', '26+28092']
        }
      ]
    },
    {
      name: 'Kashipur',
      latitude: 29.21,
      longitude: 78.96,
      title: 'Kashipur',
      address: 'Transport Nagar, Kashipur, Uttaranchal',
      spocs: [
        {
          name: 'Mr. Jahid Khan',
          contacts: ['08057639069']
        }
      ]
    },
    {
      name: 'Pune',
      latitude: 18.52,
      longitude: 73.85,
      title: 'Pune',
      address: '803, Northen Towers, near Moshi Toll Plaza , Moshi Pune – 412105',
      spocs: [
        {
          name: 'Mr. Tony',
          contacts: ['9671968800']
        }
      ]
    }
  ],
  csrImages: images
};

const AboutUs = () => {
  const [data, setData] = useState(defaultAboutUsJSON);

  React.useEffect(() => {
    const fetchPageJSON = async () => {
      try {
        const docRef = doc(db, 'jeetroadways', 'aboutUsPage');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log('Services data:', docSnap.data());
          setData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error in fetching data - ', error);
      }
    };

    fetchPageJSON();
  }, []);

  return (
    <>
      <Helmet>
        <title>Jeet Roadways | About Us</title>
        <meta
          name="description"
          content="Learn about Jeet Roadways' 30-year journey in the transport industry, providing cold chain and pharmaceutical logistics services across India."
        />
        <meta
          name="keywords"
          content="Jeet Roadways, 30 years, transport industry, cold chain, pharmaceutical logistics, trucking, warehousing, India"
        />
      </Helmet>
      <main className="about-us-container">
        <section className="about-us-banner">
          <div className="banner-images">
            <img src={data.backgroundImage} alt="About Us" />
          </div>

          <h1 className="about-us-text">{data.title}</h1>
        </section>

        <section className="about-section">
          <aside className="about-image-container">
            <img src={data.otherImages[0]} alt="Team jeetroadways" className="about-1" />
            <div className="about-image-sub-container">
              <img src={data.otherImages[1]} alt="Driver with truck" className="about-2" />
              <img src={data.otherImages[2]} alt="Men with truck and driver" className="about-3" />
            </div>
          </aside>
          <aside className="about-content">
            <div className="about-content-heading">
              <h2>{data.aboutUsTitle}</h2>
            </div>
            <div className="content">
              {data.aboutUsContent.map((content, i) => (
                <p key={i}>{content}</p>
              ))}
            </div>
          </aside>
        </section>

        <ImageSlider clientLogo image={data.clients} title="Our Clients" isSmallSlider />

        <section className="map-section">
          <MapWithTooltips cities={data.mapLocations} mapSrc="map-of-india.png" />

          <div className="map-section__content-container">
            <div className="map-section__title">
              <h2>{data.mapSectionTitle}</h2>
            </div>

            <p className="map-section__content">{data.mapSectionContent}</p>
          </div>
        </section>

        <ImageSlider backgroundImage={csr} image={data.csrImages} title="CSR" />
      </main>
    </>
  );
};
export default AboutUs;
